/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
};
export const efilingStatusSlice = createSlice({
  name: 'efilingStatus',
  initialState: initialData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setEfilingStatus: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];

      });
    },
  },
});

export const { setFieldValue, setEfilingStatus } = efilingStatusSlice.actions;
export const selectEfilingStatusData = state => state.efilingStatus;
export default efilingStatusSlice.reducer;
