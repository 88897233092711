/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {createRoot} from "react-dom/client";
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import $ from 'jquery';
// import Popper from 'popper.js';
import * as serviceWorker from './serviceWorker';
import store from './app/store';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import history from './app/history.js';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

