/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  assesseeId: '',
  syncAgain: 0,
  last_sync_status: '',
  last_synced_at: '',
  portFound: false,
};
export const currentAssesseeSlice = createSlice({
  name: 'currentAssessee',
  initialState: initialData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setCurrentAssessee: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];

      });
    },
  },
});

export const { setFieldValue, setCurrentAssessee } = currentAssesseeSlice.actions;
export const selectCurrentAssessee = state => state.currentAssessee;
export default currentAssesseeSlice.reducer;
