import moment from 'moment';

export function getFYMonthsFromAY(assessmentYear) {
    const financial_year = parseInt(assessmentYear, 10) - 1;
    const financial_next_year = assessmentYear// used for next year first 3 month
    const display_year = moment(financial_year + "-01-01").format("YY");
    const display_next_year = moment(financial_next_year + "-01-01").format("YY");
    const monthList = [
        { display: `Apr ${display_year}`, month: 'Apr', month_fullname: 'April', year: financial_year, id: 'APR', value: 4, financial_year: financial_year },
        { display: `May ${display_year}`, month: 'May', month_fullname: 'May', year: financial_year, id: 'MAY', value: 5, financial_year: financial_year },
        { display: `Jun ${display_year}`, month: 'Jun', month_fullname: 'June', year: financial_year, id: 'JUN', value: 6, financial_year: financial_year },
        { display: `Jul ${display_year}`, month: 'Jul', month_fullname: 'July', year: financial_year, id: 'JUL', value: 7, financial_year: financial_year },
        { display: `Aug ${display_year}`, month: 'Aug', month_fullname: 'August', year: financial_year, id: 'AUG', value: 8, financial_year: financial_year },
        { display: `Sep ${display_year}`, month: 'Sep', month_fullname: 'September', year: financial_year, id: 'SEP', value: 9, financial_year: financial_year },
        { display: `Oct ${display_year}`, month: 'Oct', month_fullname: 'October', year: financial_year, id: 'OCT', value: 10, financial_year: financial_year },
        { display: `Nov ${display_year}`, month: 'Nov', month_fullname: 'November', year: financial_year, id: 'NOV', value: 11, financial_year: financial_year },
        { display: `Dec ${display_year}`, month: 'Dec', month_fullname: 'December', year: financial_year, id: 'DEC', value: 12, financial_year: financial_year },
        { display: `Jan ${display_next_year}`, month: 'Jan', month_fullname: 'January', year: financial_next_year, id: 'JAN', value: 1, financial_year: financial_year },
        { display: `Feb ${display_next_year}`, month: 'Feb', month_fullname: 'February', year: financial_next_year, id: 'FEB', value: 2, financial_year: financial_year },
        { display: `Mar ${display_next_year}`, month: 'Mar', month_fullname: 'March', year: financial_next_year, id: 'MAR', value: 3, financial_year: financial_year }];
    return monthList;
}

export function getFinancialYearFromAssessmentYear(assessmentYear) {
    const financial_year = parseInt(assessmentYear, 10) - 1;
    return financial_year
}

export function getAssessmentYearFromFinancialYear(financialYear) {
    const assessmentYear = parseInt(financialYear, 10) + 1;
    return assessmentYear
}

export function convertYearToFormattedYear(year) {
    if (!year) {
        return ''
    }
    const current_year = (`${year}-${(year + 1).toString().substr(2, 2)}`)
    return current_year
}

export function convertYearToPreviousFormattedYear(year) {
    if (!year) {
        return ''
    }
    return (`${year - 1}-${(year).toString().substr(2, 2)}`)
}

export function getFinancialYearFromYearAndMonth(year, month) {
    if (month > 3) return parseInt(year, 10);
    return parseInt(year, 10) - 1;
}

export function getYearFromFinancialYearAndMonth(financialYear, financialMonth) {
    if (financialMonth > 3) return parseInt(financialYear, 10);
    return parseInt(financialYear, 10) + 1;

}

export function getFirstDateOfFinancialYear(fy) {
    return moment(`${fy}-04-01`).toDate();
}

export function getLastDateOfFinancialYear(fy) {
    return moment(`${fy+1}-03-31`).toDate();
}

// dropdown box show year list 
// key value '54','54D','54F','54G','54GA','54GB''54B in dropdown of section 
export function getdropdownShowYear (asYear,key,steps=2) {  // taken steps default value 2 and in 2024 it is 3

   
    let yearList= [];

    if(key === '54B'){
        let display_year = Number(asYear)-3
        const display_next_year = moment((display_year+1) + "-01-01").format("YY");
       return yearList=[{label:`${display_year}-${display_next_year}`,value:display_year}]
    }else if(['54','54D','54F','54G','54GA','54GB'].includes(key)){
        let display_year = Number(asYear)-4
        const display_next_year = moment((display_year+1) + "-01-01").format("YY");
       return yearList=[{label:`${display_year}-${display_next_year}`,value:display_year}]
    }

      for(let step = 4; step > steps; step--){
        let display_year = Number(asYear)-step
        const display_next_year = moment((display_year+1) + "-01-01").format("YY");
        let year = {label:`${display_year}-${display_next_year}`,value:display_year }
          yearList.push(year);
      }
     return yearList;

}

export function getdropdownInvestYear (asYear){
    let yearList = []
    for(let step = 3; step > 0; step--){
        let display_year = Number(asYear)-step
        const display_next_year = moment((display_year+1) + "-01-01").format("YY");
        let year = {label:`${display_year}-${display_next_year}`,value:display_year }
          yearList.push(year);
      }
      return yearList
}