/* eslint-disable import/prefer-default-export */
import { APP_AUTH_TOKEN } from '../app/constants';

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(APP_AUTH_TOKEN)}`,
  }
});


export const getConfigdelete = (data) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(APP_AUTH_TOKEN)}`,
  }, data
});
export const getConnectorConfig = { crossdomain: true };