import { combineReducers } from '@reduxjs/toolkit';
import appDataSlice from './appDataSlice';
import headerSliceReducer from "../features/header/headerSlice";
import regimeSliceData from "../features/header/regimeSlice";
import efilingSliceData from "../features/header/efilingStatusSlice";
import currentAssesseeData from "../features/header/currentAssesseeSlice";

const rootReducer = combineReducers({
  appData: appDataSlice,
  header: headerSliceReducer,
  regime: regimeSliceData,
  efilingStatus: efilingSliceData,
  currentAssessee: currentAssesseeData,
});

export default rootReducer;
