/* Google gtmConversionSignup_btn tag 082023 for ads tracking Signup btn click on SingUpScreen  Install By MKT */
   export function gtmConversionSignup_btn() {
    window.dataLayer = window.dataLayer || [];
    
    function gtag() {
      window.dataLayer.push(arguments);
    }
    
    gtag('event', 'conversion', {
      'send_to': 'AW-947108503/HCpnCIig06gDEJf1zsMD',
      'value': 1.0,
      'currency': 'Rupee',
    });
  }
  
  /* Google gtmConversionSignup_btn tag 082023 for ads conversion Signup
     on SingUpScreen  Install By MKT */
  export function gtmConversionSignup() {
    window.dataLayer = window.dataLayer || [];
    
    function gtag() {
      window.dataLayer.push(arguments);
    }
    
    gtag('event', 'conversion', {
      'send_to': 'AW-947108503WcKHCNzk-f8BEJf1zsMD'
    });
  }
  