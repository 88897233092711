/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import TandCExpressTDS from '../../images/TandC_ExpressTDS.pdf';

const TermsAndConditionsPopup = ({ history, ...props }) => {
  const {
    show, handleClose,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton className="mpoptextbor">
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <object data={TandCExpressTDS} type="application/pdf" width="100%" height="500">
              alt :
              {' '}
              <a href={TandCExpressTDS}>Terms & Conditions</a>
            </object>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsAndConditionsPopup;
