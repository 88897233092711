import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCircle } from '@fortawesome/free-solid-svg-icons';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { verifyItCrendital } from '../../api/localConnectorApi';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import { getClientDetail, updateClientByPortalCredentials } from '../../api/clientAPI';
import SyncAssessee from './SyncAssessee';
import { Modal } from 'react-bootstrap';
import store from '../../app/store';
import { setCurrentAssessee } from '../header/currentAssesseeSlice.js';
import "./UpdateITPassword.scss";

const UpdateITPassword = (props) => {
    const { setUpdatePassPopup, assesseeId, updatePassPopup, headerData, showSkipPassword = false, skipPassowrd = null, setPasswordVerified = null } = props

    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [startConnector, setStartConnector] = useState(false);
    const [port, setPort] = useState(0);
    const [checkItCredentials, setCheckItCredentials] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [syncValidAssessee, setSyncValidAssessee] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

    const passwordAtLeasetDigit = new RegExp(/(?=.*\d)[\S]{1,}$/);
    const passwordAtLeasetUpperCase = new RegExp(/(?=.*[A-Z])[\S]{1,}$/);
    const passwordAtLeasetLowerCase = new RegExp(/(?=.*[a-z])[\S]{1,}$/);
    const passwordAtLeasetSpecial = new RegExp(/(?=.*[@$!%*?&])[\S]{1,}$/);
    const passwordAtLeasetEight = new RegExp(/[\S]{8,}$/);
    const passwordAtLeasetFourteen = new RegExp(/[\S]{14,}$/);

    const verifyPassword = () => {
        if (password.length === 0) {
            setPasswordError('This field is required');
            return
        }
        setStartConnector(true)
    }
    const verfiyCredApi = () => {
        setError(null);
        const request = {
            "itdportal_username": headerData?.assessee_pan ?? '',
            "itdportal_password": password,
            "logout_immediately": 1
        };

        async function fetchEverything() {
            try {
                setLoading(true);
                const result = await verifyItCrendital(port, request);
                if (result.data.status === 1) {
                    const responseHeader = result.headers ?? null;
                    let extraHeader = {};
                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;


                    (async () => {
                        const rowData = {}
                        rowData.data = result?.data?.data
                        try {
                            await updateClientByPortalCredentials(rowData, extraHeader, assesseeId);
                            fetchClientDetail();// we need to call this api for update header 
                            store.dispatch(setCurrentAssessee({ syncAgain: 1, last_sync_status: 'IN_PROGRESS', last_synced_at: new Date() }))
                            setSyncValidAssessee(assesseeId);
                        } catch (err) {
                            // setError(err);
                        }
                    })();
                    if (setPasswordVerified) {
                        setPasswordVerified(true)
                    }
                    setSuccess('Successfully verified.');

                }
            } catch (err) {
                setPasswordError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message ?? CONNECTOR_ERRORS_MAPPING['default']);
                setCheckItCredentials(false);
            } finally {
                setLoading(false);
            }
        }
        fetchEverything();
    };



    const fetchClientDetail = () => {
        async function callClientApi() {
            setLoading(true);
            try {
                await getClientDetail(assesseeId);

            } catch (err) {
                setError(err);
            }
            setLoading(false);
        }
        callClientApi();
    }


    useEffect(() => {
        if (port && checkItCredentials) {
            verfiyCredApi()
        }
        //eslint-disable-next-line
    }, [port, checkItCredentials])

    return (
        <>

            {(loading) ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }} />
            {password && startConnector
                ? <ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={() => {
                        setCheckItCredentials(true)
                    }}
                />
                : null
            }
            {syncValidAssessee &&
                <SyncAssessee
                    assesseeId={assesseeId}
                    handleProceed={() => {
                        setSyncValidAssessee(null);
                        setUpdatePassPopup(false)

                    }}
                    port={port}
                />
            }
            <Modal
                className="modal fade mysubplan_addon_modalboxpopup updateItPassword"
                backdrop="static"
                show={updatePassPopup}
                onHide={() => setUpdatePassPopup(false)}
            >
                <Modal.Header closeButton className="mysubplan_addon_modalboxpopup_header" style={{ padding: "2px 10px", background: "#f4f4f4" }}>
                    <Modal.Title style={{ fontSize: "14px" }}>Not Verified Password</Modal.Title>
                </Modal.Header>

                <Modal.Body className='p-0'>
                    <div className="modal-body">
                        <div style={{ color: "#31719b", fontSize: "12px" }}>We do not have your valid Income Tax Password to sync your data. Enter password to continue </div>
                        <div className='row'>

                            <div className="input-group col-md-12 mt-2" style={{ alignItems: "baseline" }}>
                                <label htmlFor="password" className="" style={{ fontSize: "14px" }}>Password</label>
                                <div className="input-group ml-4" style={{ width: "180px", justifyContent: 'start', flexGrow: 1 }}>
                                    {
                                        showPassword
                                            ?
                                            <>
                                                <input
                                                    type="input"
                                                    className="form-control itr-update-input"
                                                    name="password"
                                                    id="password"
                                                    maxLength={14}
                                                    value={password ?? ''}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    autocomplete="new-password"
                                                    onFocus={() => setShowPasswordTooltip(true)}
                                                    onBlur={(e) => {
                                                        setShowPasswordTooltip(false);
                                                    }}
                                                />
                                                <div className="input-group-prepend expit_clmast_individual_eyebox itr-update-input-show"
                                                    onClick={() => setShowPassword(false)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <input
                                                    type="password"
                                                    className="form-control itr-update-input"
                                                    name="password"
                                                    id="password"
                                                    maxLength={14}
                                                    value={password ?? ''}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    autocomplete="new-password"
                                                    onFocus={() => setShowPasswordTooltip(true)}
                                                    onBlur={(e) => {
                                                        setShowPasswordTooltip(false);
                                                    }}
                                                />
                                                <div className="input-group-prepend expit_clmast_individual_eyebox itr-update-input-show"
                                                    onClick={() => setShowPassword(true)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </div>
                                            </>
                                    }
                                    {showPasswordTooltip && (
                                        <div className="paswordtoolbox">
                                            <div className="passlist">
                                                <p className="passmust">Password must have</p>
                                                <span>
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetEight.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    Min. 8 letters
                                                </span>
                                                <span>
                                                    <br />
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetFourteen.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    Max. 14 letters
                                                </span>
                                                <br />
                                                <span>
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetUpperCase.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    One Upper case letter
                                                </span>
                                                <br />
                                                <span>
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetLowerCase.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    One Lower case letter
                                                </span>
                                                <br />
                                                <span>
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetDigit.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    One Number
                                                </span>

                                                <br />
                                                <span>
                                                    <span className="passlisticon">
                                                        <FontAwesomeIcon
                                                            icon={faCircle}
                                                            color={passwordAtLeasetSpecial.test(password) ? 'green' : ''}
                                                        />
                                                    </span>
                                                    Have One Special Character (e.g. @,#,$,%)
                                                </span>


                                            </div>
                                        </div>
                                    )}
                                    <div className="mt-2" style={{ flex: "100%", color: "red", height: "20px", fontSize: "12px" }}>{passwordError}</div>

                                </div>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-md-12 text-center">
                                <button className="addbus_donepress_btnbgcle mr-1" onClick={() => verifyPassword()}>Update Now & Continue</button>
                                {showSkipPassword ? <button className="addbus_cancel_btnbgcle mr-1" onClick={() => skipPassowrd()}>Skip</button> : null
                                    // <button className="addbus_cancel_btnbgcle" data-dismiss="modal" onClick={() => setUpdatePassPopup(false)}>Cancel</button>
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default UpdateITPassword