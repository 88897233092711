import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRegimeData } from './regimeSlice';
import store from '../../app/store';
import { setRegime } from './regimeSlice';
import { callApi } from '../../api/businessProfessionApi';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { REGIME_TOGGLE_PATHS } from '../../App';
import $ from 'jquery';
import { selectHeaderData } from './headerSlice';

const RegimeToggle = (props) => {
    const { asYears,setError } = props;

    const location = useLocation();
    const params = queryString.parse(location.search);
    const returnId = params?.return_id;
    const regimeData = useSelector(selectRegimeData);
    const [preToggle, setPreToggle] = useState("NA");
    const [currToggle, setCurrToggle] = useState("NA");
    const [pyToggleEnable, setPyToggleEnable] = useState(true);

    const headerData = useSelector(selectHeaderData);

    const callComapreRegime = (AY, status) => {
        // let statusArray = true;
        if (AY < 2024 && status === 5) {
            return false
        } else {
            return true
        }
        //     if (AY >= 2024) {
        //        statusArray.push(5, 6, 8, 22, 23, 24, 25, 27, 28, 29)
        //    }
        //    return statusArray
    };

    const API_URL = '/api/v1/computation/tax-regime'

    const callOnLoadData = async (returnId, assesseeId) => {
        try {
            const result = await callApi('get', API_URL, { assessee_id: assesseeId, return_id: returnId });
            if (result?.data) {
                setCurrToggle(result?.data?.flag_cy);
                setPreToggle(result?.data?.flag_py);
                setPyToggleEnable(result?.data?.flag_py_edit);
                store.dispatch(setRegime({ flag_cy: result?.data?.flag_cy, flag_py: result?.data?.flag_py }))

            }
        } catch (err) {
        }
    };

    const updateData = (key, value) => {
        const rowData = {}
        rowData.assessee_id = params?.assessee_id
        rowData.return_id = returnId;
        if (key) {
            rowData[key] = value;
        }
        async function callUpdateApi() {
            try {
                const result = await callApi('put', '/api/v1/computation/tax-regime', rowData);
                if (result?.data) {
                    store.dispatch(setRegime({ [key]: value }))
                    if (key === "flag_py") {
                        setPreToggle(value);
                    } else if (key === "flag_cy") {
                        setCurrToggle(value);
                    }

                }
            } catch (err) {
            if (err?.message){
                 setError(err?.message)
            }else{
                setError(err)
            }
            }
        }
        callUpdateApi();

    }

    useEffect(() => {
        if (returnId) {
            callOnLoadData(returnId, params?.assessee_id);
        }
    }, [returnId, params?.assessee_id]);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    return (

        <li className="regimebox_header">
            <div className="yrregime_textbox">
                {callComapreRegime(headerData?.asyear, headerData?.assessee_status) &&
                    <div className={`leftside_preyrreg_textfs ${pyToggleEnable ? '' : 'disabled'}`}>
                        <span className="pre_current_textfsgrey" data-toggle="tooltip" data-placement="top" data-original-title="Previous Year Regime">
                            PY Regime
                        </span>
                        <span className="header-tax-box" style={{ visibility: "hidden" }}>{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyNew}</span>}</span>

                        <span className="reg_rightsidetextfs pr-2" onClick={() => { if ((preToggle ?? null) !== "NEW" && pyToggleEnable) { updateData("flag_py", "NEW"); } }}>New</span>
                        <span className="efiling_statetoggle">
                            <span className={`efiling_statetoggle_button efiling_togglebtn_01 ${preToggle === "NEW" ? "active " : ''}`} id="toggle-button1"
                                onClick={() => { if ((preToggle ?? null) !== "NEW" && pyToggleEnable) { updateData("flag_py", "NEW"); } }}
                            >
                            </span>

                            <span className={`efiling_statetoggle_button efiling_togglebtn_02 ${preToggle === "NA" ? "active " : ''}`} id="toggle-button2"
                                onClick={() => { if ((preToggle ?? null) !== "NA" && pyToggleEnable) { updateData("flag_py", "NA") } }}
                            >

                            </span>

                            <span className={`efiling_statetoggle_button efiling_togglebtn_03 ${preToggle === "OLD" ? "active " : ''}`} id="toggle-button3"
                                onClick={() => { if ((preToggle ?? null) !== "OLD" && pyToggleEnable) { updateData("flag_py", "OLD") } }}
                            >

                            </span>

                        </span>
                        <span className="reg_rightsidetextfs pl-2" style={{ color: "green" }} onClick={() => { if ((preToggle ?? null) !== "OLD" && pyToggleEnable) { updateData("flag_py", "OLD") } }}>Old</span>
                        <span className="header-tax-box" style={{ visibility: "hidden" }}>{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyOld}</span>}</span>

                    </div>
                }
                {asYears && asYears >= 2024 ?
                    <div className="leftside_cureentbottom_textfs">
                        <span className="currentyr_regtextfs">
                            <span className="pre_current_textfsgrey" data-toggle="tooltip" data-placement="top" data-original-title="Current Year Regime">
                                CY Regime
                            </span>
                            <span className="header-tax-box">{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyNew}</span>}</span>
                            <span className="reg_rightsidetextfs pr-2" onClick={() => { if ((currToggle ?? null) !== "NEW") { updateData("flag_cy", "NEW") } }}>New</span>
                            <span className="efiling_statetoggle_ht02">

                                <span className={`efiling_statetoggle_button__ht02 efiling_togglebtn_01 ${currToggle === "NEW" ? "active " : ''}`} id="toggle-button2"
                                    onClick={() => { if ((currToggle ?? null) !== "NEW") { updateData("flag_cy", "NEW") } }}
                                >
                                </span>
                                <span className='efiling_statetoggle_button__ht02 efiling_togglebtn_02' ></span>
                                <span className={`efiling_statetoggle_button__ht02 efiling_togglebtn_03 ${currToggle === "OLD" ? "active " : ''}`} id="toggle-button3"
                                    onClick={() => { if ((currToggle ?? null) !== "OLD") { updateData("flag_cy", "OLD") } }}
                                >

                                </span>

                            </span>
                            <span className="reg_rightsidetextfs pl-2" style={{ color: "green" }} onClick={() => { if ((currToggle ?? null) !== "OLD") { updateData("flag_cy", "OLD") } }}>Old</span>
                            <span className="header-tax-box">{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyOld}</span>}</span>

                        </span>
                    </div>
                    :
                    <div className="leftside_cureentbottom_textfs">
                        <span className="currentyr_regtextfs">
                            <span className="pre_current_textfsgrey" data-toggle="tooltip" data-placement="top" data-original-title="Current Year Regime">
                                CY Regime
                            </span>
                            <span className="header-tax-box">{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyNew}</span>}</span>
                            <span className="reg_rightsidetextfs pr-2" onClick={() => { if ((currToggle ?? null) !== "NEW") { updateData("flag_cy", "NEW") } }}>New</span>
                            <span className="efiling_statetoggle_ht02">

                                <span className={`efiling_statetoggle_button__ht02 efiling_togglebtn_01 ${currToggle === "NEW" ? "active " : ''}`} id="toggle-button2"
                                    onClick={() => { if ((currToggle ?? null) !== "NEW") { updateData("flag_cy", "NEW") } }}
                                >
                                </span>
                                <span className={`efiling_statetoggle_button__ht02 efiling_togglebtn_02 ${currToggle === "NA" ? "active " : ''}`} id="toggle-button2"
                                    onClick={() => { if ((currToggle ?? null) !== "NA") { updateData("flag_cy", "NA") } }}
                                >
                                </span>
                                <span className={`efiling_statetoggle_button__ht02 efiling_togglebtn_03 ${currToggle === "OLD" ? "active " : ''}`} id="toggle-button3"
                                    onClick={() => { if ((currToggle ?? null) !== "OLD") { updateData("flag_cy", "OLD") } }}
                                >

                                </span>

                            </span>
                            <span className="reg_rightsidetextfs pl-2" style={{ color: "green" }} onClick={() => { if ((currToggle ?? null) !== "OLD") { updateData("flag_cy", "OLD") } }}>Old</span>
                            <span className="header-tax-box">{REGIME_TOGGLE_PATHS.includes(location.pathname) && <span className="header-tax-amount">{regimeData?.netTaxCyOld}</span>}</span>

                        </span>
                    </div>
                }

            </div>
        </li>
    )
}

export default RegimeToggle