import React, { useEffect, useState } from 'react'
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter'
import { callSyncDashboard, starySync, syncPipeLineStart } from '../../api/dashboardApi';
import { HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';
import moment from 'moment';
import { syncDashbaord } from '../../api/clientAPI';
import { selectCurrentAssessee } from '../header/currentAssesseeSlice.js';
import { useSelector } from 'react-redux';


const SyncAssessee = (props) => {
    const { assesseeId, handleProceed, port: receivedPort = null, hardResync = false } = props
    const [port, setPort] = useState(receivedPort ?? 0)
    const [isRunSync, setIsRunSync] = useState(true)
    const [runSync, setRunSync] = useState(port ? true : false)
    const cureentAssessee = useSelector(selectCurrentAssessee);

    const addSyncTimeLocal = (mobile, pan) => {
        const uniqueId = mobile + '-' + pan;
        const currentDate = moment(new Date()).format('YYYY-MM-DD')
        const expressItLastSync = JSON.parse(localStorage.getItem('expressit-dash-sync')) ?? {}
        expressItLastSync[uniqueId] = currentDate
        localStorage.setItem('expressit-dash-sync', JSON.stringify(expressItLastSync))
    }

    const syncDashboard = async (assesseeId, port) => {
        if (port && assesseeId) {
            try {
                const result = await syncDashbaord(assesseeId);
                if (result?.data) {
                    const responseData = result.data ?? {};
                    if (responseData?.sync_again || cureentAssessee?.syncAgain || hardResync) {
                        try {
                            const connectorResponse = await callSyncDashboard(port, { logout_immediately: 0, itdportal_username: responseData?.itportal_username, itdportal_password: responseData?.itportal_password },);
                            if (Number(connectorResponse.status) === 1) {
                                const decode = JSON.parse(atob(connectorResponse.data))
                                try {
                                    const result = await syncPipeLineStart({ itd_action_ref: decode?.itd_action_ref, assessee_id: assesseeId, ...((cureentAssessee?.syncAgain || hardResync) && { is_hard_refresh: true }), });
                                    const responseData = result.data ?? null;
                                    const responseHeader = result.headers ?? null;
                                    const connectorRequest = {
                                        "data": responseData?.data
                                    };
                                    let extraHeader = {};
                                    extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
                                    try {
                                        const connectorResponse = await starySync(port, connectorRequest, extraHeader);
                                        if (Number(connectorResponse.status) === 1) {
                                            addSyncTimeLocal(result?.data?.header?.mobile_number, result?.data?.header?.assessee_pan)
                                        }
                                    } catch (err) {
                                        // setError(err.message);
                                    }
                                } catch (err) {
                                    // setError(err);
                                }
                            }
                        } catch (err) {
                            //setError(err.message); 
                        }
                    } else {
                        addSyncTimeLocal(result?.header?.mobile_number, result?.header?.assessee_pan)

                    }
                }
            } catch (err) {
                // setError(err);
            } finally {
                setRunSync(true)
                if (handleProceed) {
                    handleProceed()

                }
            }
        }
    };


    useEffect(() => {
        if (port && runSync && assesseeId) {
            syncDashboard(assesseeId, port);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [port, runSync, assesseeId])

    return (
        <>
            {(isRunSync && assesseeId && !runSync)
                ? <ConnectorAdapter
                    show={isRunSync}
                    handleClose={() => { setIsRunSync(false); }}
                    setPort={setPort}
                    handleProceed={() => {
                        setRunSync(true);
                    }
                    }
                />
                : null
            }


        </>
    )
}

export default SyncAssessee