/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleErrorWithCode, handleError } from './responseHandler';
import { getConfig, getConfigdelete } from './common';
import { APP_AUTH_TOKEN } from '../app/constants';

export async function getCompanyList(data) {
  return [];

}

export async function addClientByPortalCredentials(data, headers) {
  const url = `${BASE_URL}/api/v1/assesseemanager/add-itd`;

  try {
    const clientData = await axios.post(url, data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(APP_AUTH_TOKEN)}`,
          ...headers
        }
      }
    );

    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.errors);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function sendOTPForSignUp123(data) {
  const url = `${BASE_URL}/api/v1/auth/send-otp-signup`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function addClientByManualData(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/add-details`;

  try {
    const clientData = await axios.post(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {

    throw handleError(err);
  }

}

export async function getClientlist() {
  const url = `${BASE_URL}/api/v1/assesseemanager/list-recent?limit=17`;

  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function getClientAlllist(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/list-all`;

  try {
    const clientAllData = await axios.post(url, data, getConfig());
    if (Number(clientAllData.data.status) !== 1) {
      throw (clientAllData.data.message);
    }
    return handleSuccess(clientAllData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}


export async function importBulkData(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/bulkimport`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadExcelTemplate() {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL}/api/v1/assesseemanager/excel-template`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'ImportClients_Excel_Workbook_Template.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getClientDetail(assessee_id, updateHeader = true) {
  const url = `${BASE_URL}/api/v1/assesseemanager/details?assessee_id=${assessee_id}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    if (updateHeader) return handleSuccess(clientData.data);
    return clientData.data;
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function updateClientByManualData(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/details`;

  try {
    const clientData = await axios.put(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {

    throw handleError(err);
  }

}


export async function getDetailFromIfsc(data) {
  const url = `${BASE_URL}/api/v1/searchinfo/ifsc`;

  try {
    const clientData = await axios.post(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {

    throw handleError(err);
  }

}


export async function callBankApi(data) {
  const url = `${BASE_URL}/api/v1/searchinfo/ifsc`;

  try {
    const clientData = await axios.post(url, data, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {

    throw handleError(err);
  }

}


export async function updateClientByPortalCredentials(data, headers, assessee_id) {
  const url = `${BASE_URL}/api/v1/assesseemanager/update-itd?assessee_id=${assessee_id}`;

  try {
    const clientData = await axios.post(url, data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(APP_AUTH_TOKEN)}`,
          ...headers
        }
      }
    );

    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.errors);
    }
    // Dont call handleSuccess here which updates header also
    return clientData.data;
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function getReSyncClientList(params) {
  const url = `${BASE_URL}/api/v1/assesseemanager/re-sync-details${params}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      // Dont fail in this API, just return empty array
      return [];
    }
    return clientData.data;
  }
  catch (err) {
    // Dont fail in this API, just return empty array
    return [];
  }

}

export async function syncDashbaord(assesseeId) {
  const url = `${BASE_URL}/api/v1/itportal/credentials-sync?assessee_id=${assesseeId}`;
  try {
    const clientData = await axios.get(url, getConfig());
    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return clientData.data;
  }
  catch (err) {
    throw handleError(err);
  }

}



export async function importBulkDataJson(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/bulkimport-itr-json-details`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getPendingClientlist(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/pending/list-all`;

  try {
    const clientAllData = await axios.post(url, data, getConfig());
    if (Number(clientAllData.data.status) !== 1) {
      throw (clientAllData.data.message);
    }
    return handleSuccess(clientAllData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function downloadExcelDeductorlist() {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL}/api/v1/assesseemanager/export`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'client List.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function PendingClientlistUpdate(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/pending`;

  try {
    const clientAllData = await axios.put(url, data, getConfig());
    if (Number(clientAllData.data.status) !== 1) {
      throw (clientAllData.data.message);
    }
    return handleSuccess(clientAllData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function deleteClientOnServer(data) {
  const url = `${BASE_URL}/api/v1/assesseemanager/pending`;

  try {

    const clientData = await axios.delete(url, getConfigdelete(data));

    if (Number(clientData.data.status) !== 1) {
      throw (clientData.data.message);
    }
    return handleSuccess(clientData.data);
  }
  catch (err) {
    throw handleError(err);
  }

}