/* eslint-disable no-throw-literal */
import axios from "axios";
import { getConfig, getConnectorConfig } from "./common";
import { handleConnectorError, handleError } from "./responseHandler";
import { BASE_URL } from "../app/constants";

const connectorError = "Unable to connect to the Express Connector. Please ensure that the Expressor Connector is running. If the problem still persists, then Close the Browser completely and restart.";


export async function getConnectionStatus(port) {

    if (!port) throw { code: 404, message: connectorError };

    const url = `http://127.0.0.1:${port}/api/v1/status`;

    try {
        const result = await axios.get(url, { ...getConfig(), getConnectorConfig });

        return result.data;
    } catch (err) {
        const response = handleConnectorError(err);

        if (response.code === 404) {
            throw { code: 404, message: connectorError };
        } else {
            throw response;
        }
    }
}


export async function verifyItCrendital(port, data) {
    if (!port) throw { code: 404, message: connectorError };

    const url = `http://127.0.0.1:${port}/api/v1/itr/itefl/add-itd`;
    try {
        const result = await axios.post(url, data);
        return result;
    } catch (err) {
        const response = handleConnectorError(err);
        if (response.code === 404) {
            throw { code: 404, message: connectorError };
        } else {
            throw response;
        }
    }
}

export async function verifyItLoginCrendital(port, data) {
    if (!port) throw { code: 404, message: connectorError };

    const url = `http://127.0.0.1:${port}/api/v1/itr/itefl/login`;
    try {
        const result = await axios.post(url, data);
        return result;
    } catch (err) {
        const response = handleConnectorError(err);
        if (response.code === 404) {
            throw { code: 404, message: connectorError };
        } else {
            throw response;
        }
    }
}

export async function getActionStatus(port, action_id) {

    const url = `http://127.0.0.1:${port}/api/v1/itr/itportal/gui/action/status?action_id=${action_id}`;

    try {
        const result = await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
        return { data: result.data, headers: result.headers };
    } catch (err) {
        const response = handleConnectorError(err);
        if (response.code === 401 || response.code === 404) {
            throw { code: response.code, message: connectorError };
        } else {
            throw response;
        }
    }
}


export async function searchItrJson() {
    const url = `${BASE_URL}/api/v1/assessee-manager/search-itr-json`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result;
    } catch (err) {
        throw handleError(err);
    }
}

export async function runSearchItrJsonConnector(port, data, headers) {
    if (!port) throw { code: 404, message: connectorError };

    const url = `http://127.0.0.1:${port}/api/v1/itr/custom/action/perform`;
    try {
        const result = await axios.post(url, data, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...headers,
            }
        });
        return result.data;
    } catch (err) {
        const response = handleConnectorError(err);
        if (response.code === 404) {
            throw { code: 404, message: connectorError };
        } else {
            throw response;
        }
    }
}

export async function getProcessIdStatus(port, processId) {

    const url = `http://127.0.0.1:${port}/api/v1/itr/custom/action/status?process_id=${processId}`;
    try {
        const result = await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
        return { data: result.data, headers: result.headers };
    } catch (err) {
        const response = handleConnectorError(err);
        if (response.code === 401 || response.code === 404) {
            throw { code: response.code, message: connectorError };
        } else {
            throw response;
        }
    }
}