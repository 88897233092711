import React, { useState } from 'react'
import righticon from '../../../images/errow_right.png';
import { callApi, getPyComputationData } from '../../../api/businessProfessionApi';
import { selectHeaderData } from '../../header/headerSlice';
import { useSelector } from 'react-redux';
import ShowAlert from '../../../components/ShowAlert';
import { getReturnId } from '../../../api/dashboardApi';
import './compare-regime.scss'
const CompareRegime = (props) => {

    const { assesseeId, returnId, ay = null } = props;
    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);
    const [newRegimeData, setNewRegimeData] = useState({});
    const [oldRegimeData, setOldRegimeData] = useState({});
    const [previousData, setPreviousData] = useState();
    const API_URL = '/api/v1/computation';
    const headerData = useSelector(selectHeaderData);

    const fetchRecord = async (assesseeId, returnId) => {
        if (assesseeId && returnId) {
            // let flagvalue = "115BAD"
            // if ([1, 2].includes(headerData?.assessee_status)) {
            //     flagvalue = "115BAC"
            // }
            callComputationData(returnId, assesseeId, "NEW_REGIME", setNewRegimeData);
            callComputationData(returnId, assesseeId, "OLD_REGIME", setOldRegimeData);
            callPreviousYearData(assesseeId, (headerData?.asyear - 1));
        }
    }
    const callComputationData = async (returnId, assesseeId, flag, setData) => {
        try {
            const result = await callApi("get", `${API_URL}?assessee_id=${assesseeId}&return_id=${returnId}&section=${flag}`, null);
            if (result?.data) {
                setData(() => {
                    return { ...result?.data }
                })
            }
        } catch (err) {
            setError(err);
        }
    };

    const callPreviousYearData = async (assesseeId, ay) => {
        try {
            const returnResult = await getReturnId(assesseeId, ay);
            if (returnResult?.data?.return_id) {
                try {
                    const result = await getPyComputationData(returnResult?.data?.return_id, assesseeId);
                    if (result?.data) {
                        setPreviousData(() => {
                            return { ...result?.data }
                        })
                    }
                } catch (err) {
                    setError(err);
                }

            }
        } catch (err) {
            // setError(err);
        }
    };


    if (headerData?.asyear >= 2023 || ay >= 2023) {
        return (
            <>
                <ShowAlert
                    error={error}
                    success={success}
                    onClose={() => { setError(null); setSuccess(null); }}
                />
                <div className="oldvs_newrem_rightside_popupbox" data-toggle="modal" data-target="#comprsignoldpopup" onClick={() => { setShowModal(true) }}>
                    <span className="mb-0 btn old_compare_btnfs" type="button" onClick={() => fetchRecord(assesseeId, returnId)}>
                        Old vs New Tax Regime
                        <span className="oldvs_uploaddataicon">
                            <img alt="summary" id="summv_myImg" src={righticon} />
                        </span>

                    </span>
                </div>


                {showModal &&
                    <div className="modal fade comprsignold_popup" id="comprsignoldpopup" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog">
                            <div className="modal-content olsvsnew_contanimat">
                                <div className="modal-header comprsignold_popup_header">
                                    <h4 className="modal-title">Old vs New Income Tax Regime</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => { setShowModal(false) }}>×</button>
                                </div>

                                {headerData?.asyear >= 2024 || ay >= 2024 ?

                                    <div className="modal-body pt-2 pb-1">
                                        <div className=" table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ overflow: 'auto', maxHeight: '600px' }}>
                                            <table className="table table-striped expit_comp_newold_tb dash_oldvsnew_tbheightfixed mb-0">

                                                <thead>
                                                    <tr className="newold_trrow_topbor">
                                                        <th width="140" rowSpan="2" className="">Income & Tax Details</th>
                                                        <th width="110" colSpan="2" className="comp_newold_bgclr_lightyell">Current Year ({`${headerData?.asyear} - ${String((Number(headerData?.asyear) + 1)).slice(-2)}`})</th>
                                                        <th width="140" class="comp_newold_bgclr_purpal">Previous Year ({`${headerData?.asyear - 1} - ${String((Number(headerData?.asyear))).slice(-2)}`})</th>

                                                    </tr>
                                                    <tr className="newold_trrow_topbor">
                                                        <th width="110" className="comp_newold_bgclr_lightyell">New Regime ({newRegimeData?.SectionTASR ?? '115BAD'}) </th>
                                                        {/* <th width="110" className="comp_newold_bgclr_lightyell">New Regime ({[1, 2].includes(headerData?.assessee_status) ? '115BAC' : '115BAD'})</th> */}
                                                        <th width="110" className="comp_newold_bgclr_lightyell">Old Regime </th>
                                                        <th width="130" class="comp_newold_bgclr_purpal">{['115BAC', '115BAD'].includes(previousData?.SectionTASR) ? 'New Regime' : 'Old Regime'} </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {((oldRegimeData?.IncomeFromSalary?.Amount ?? 0) > 0 || (newRegimeData?.IncomeFromSalary?.Amount ?? 0) > 0 || (previousData?.IncomeFromSalary?.Amount ?? 0) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Salary " target="_self" rel="noreferrer">
                                                                    Salary
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0 || (previousData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="House Property " target="_self" rel="noreferrer">
                                                                    House Property
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {
                                                    }
                                                    {((oldRegimeData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0 || (previousData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Capital Gain" target="_self" rel="noreferrer">
                                                                    Capital Gain
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromOtherSources?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromOtherSources?.Amount ?? 0) !== 0 || (previousData?.IncomeFromOtherSources?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Other Source" target="_self" rel="noreferrer">
                                                                    Other Source
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0 || (previousData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Business Income" target="_self" rel="noreferrer">
                                                                    Business Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.ExemptIncomes?.Amount ?? null) > 0 || (newRegimeData?.ExemptIncomes?.Amount ?? null) > 0 || (previousData?.ExemptIncomes?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Exempt Income" target="_self" rel="noreferrer">
                                                                    Exempt Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {(((oldRegimeData?.ExemptIncomes?.Amount ?? 0) - (oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0 || ((newRegimeData?.ExemptIncomes?.Amount ?? 0) - (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0 || ((previousData?.ExemptIncomes?.Amount ?? 0) - (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Other Exempt" target="_self" rel="noreferrer">
                                                                    Other Exempt
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.ExemptIncomes?.Amount ?? 0) - (oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.ExemptIncomes?.Amount ?? 0) - (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_purpal">{((previousData?.ExemptIncomes?.Amount ?? 0) - (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0 || (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0 || (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Agriculture Income" target="_self" rel="noreferrer">
                                                                    Agriculture Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0 || (newRegimeData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0 || (previousData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Deduction" target="_self" rel="noreferrer">
                                                                    Deduction
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TotalIncomeRounded288A ?? null) > 0 || (newRegimeData?.TotalIncomeRounded288A ?? null) > 0 || (previousData?.TotalIncomeRounded288A ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Net Income" target="_self" rel="noreferrer">
                                                                    Net Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0) &&
                                                        <tr className="comp_newold_totaltextbold">
                                                            <td>Total Tax</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_purpal">{((previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Rebate" target="_self" rel="noreferrer">
                                                                    Rebate
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell"></td>
                                                            <td className="text-right comp_newold_bgclr_lightyell"></td>
                                                            <td class="text-right comp_newold_bgclr_purpal"></td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 87A" target="_self" rel="noreferrer">
                                                                    Section 87A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Rebate on agricultural income" target="_self" rel="noreferrer">
                                                                    Rebate on agricultural income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Surcharge" target="_self" rel="noreferrer">
                                                                    Surcharge
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Health & Education Cess" target="_self" rel="noreferrer">
                                                                    Health & Education Cess
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Tax Payable" target="_self" rel="noreferrer">
                                                                    Tax Payable
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Relief" target="_self" rel="noreferrer">
                                                                    Relief
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 89" target="_self" rel="noreferrer">
                                                                    Section 89
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 90/ 90A" target="_self" rel="noreferrer">
                                                                    Section 90/ 90A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 91" target="_self" rel="noreferrer">
                                                                    Section 91
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0 || (previousData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0) &&
                                                        <tr>
                                                            <td> Balance Tax Payable</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }

                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="TDS/TCS" target="_self" rel="noreferrer">
                                                                    TDS/TCS
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{((previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (previousData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Advance Tax" target="_self" rel="noreferrer">
                                                                    Advance Tax
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Self Assessment Tax" target="_self" rel="noreferrer">
                                                                    Self Assessment Tax
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="(+)  Interest" target="_self" rel="noreferrer">
                                                                    (+)  Interest
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="234 A" target="_self" rel="noreferrer">
                                                                    234 A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4">  234 B</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4"> 234 C</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4"> 234 F</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    <tr className="comp_newold_totaltextbold">
                                                        <td>Tax Payable/Refundable</td>
                                                        <td className="text-right comp_newold_bgclr_lightyell">{newRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (newRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `(${(newRegimeData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>
                                                        <td className="text-right comp_newold_bgclr_lightyell">{oldRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (oldRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `(${(oldRegimeData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>

                                                        <td class="text-right comp_newold_bgclr_purpal">{previousData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (previousData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `( ${(previousData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    :

                                    <div className="modal-body pt-2 pb-1">
                                        <div className=" table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ overflow: 'auto', maxHeight: '600px' }}>
                                            <table className="table table-striped expit_comp_newold_tb dash_oldvsnew_tbheightfixed mb-0">

                                                <thead>
                                                    <tr className="newold_trrow_topbor">
                                                        <th width="140" rowSpan="2" className="">Income & Tax Details</th>
                                                        <th width="110" colSpan="2" className="comp_newold_bgclr_lightyell">Current Year ({`${headerData?.asyear} - ${String((Number(headerData?.asyear) + 1)).slice(-2)}`})</th>
                                                        <th width="140" class="comp_newold_bgclr_purpal">Previous Year ({`${headerData?.asyear - 1} - ${String((Number(headerData?.asyear))).slice(-2)}`})</th>

                                                    </tr>
                                                    <tr className="newold_trrow_topbor">
                                                        <th width="110" className="comp_newold_bgclr_lightyell">Old Regime </th>
                                                        {/* <th width="110" className="comp_newold_bgclr_lightyell">New Regime ({[1, 2].includes(headerData?.assessee_status) ? '115BAC' : '115BAD'})</th> */}
                                                        <th width="110" className="comp_newold_bgclr_lightyell">New Regime ({newRegimeData?.SectionTASR ?? '115BAD'})</th>
                                                        <th width="130" class="comp_newold_bgclr_purpal">{['115BAC', '115BAD'].includes(previousData?.SectionTASR) ? 'New Regime' : 'Old Regime'} </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {((oldRegimeData?.IncomeFromSalary?.Amount ?? 0) > 0 || (newRegimeData?.IncomeFromSalary?.Amount ?? 0) > 0 || (previousData?.IncomeFromSalary?.Amount ?? 0) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Salary " target="_self" rel="noreferrer">
                                                                    Salary
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromSalary?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0 || (previousData?.IncomeFromHouseProperty?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="House Property " target="_self" rel="noreferrer">
                                                                    House Property
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromHouseProperty?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {
                                                    }
                                                    {((oldRegimeData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0 || (previousData?.IncomeFromCapitalGains?.Amount ?? 0) !== 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Capital Gain" target="_self" rel="noreferrer">
                                                                    Capital Gain
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromCapitalGains?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromOtherSources?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromOtherSources?.Amount ?? 0) !== 0 || (previousData?.IncomeFromOtherSources?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Other Source" target="_self" rel="noreferrer">
                                                                    Other Source
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromOtherSources?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0 || (newRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0 || (previousData?.IncomeFromBusinessProfession?.Amount ?? 0) !== 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Business Income" target="_self" rel="noreferrer">
                                                                    Business Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.IncomeFromBusinessProfession?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.ExemptIncomes?.Amount ?? null) > 0 || (newRegimeData?.ExemptIncomes?.Amount ?? null) > 0 || (previousData?.ExemptIncomes?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Exempt Income" target="_self" rel="noreferrer">
                                                                    Exempt Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.ExemptIncomes?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {(((oldRegimeData?.ExemptIncomes?.Amount ?? 0) - (oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0 || ((newRegimeData?.ExemptIncomes?.Amount ?? 0) - (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0 || ((previousData?.ExemptIncomes?.Amount ?? 0) - (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Other Exempt" target="_self" rel="noreferrer">
                                                                    Other Exempt
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.ExemptIncomes?.Amount ?? 0) - (oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.ExemptIncomes?.Amount ?? 0) - (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_purpal">{((previousData?.ExemptIncomes?.Amount ?? 0) - (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0))?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0 || (newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0 || (previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Agriculture Income" target="_self" rel="noreferrer">
                                                                    Agriculture Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.ExemptIncomes?.Items?.agricul_incm?.agri_2v_amt ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0 || (newRegimeData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0 || (previousData?.DeductionsUnderChapterVIA?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Deduction" target="_self" rel="noreferrer">
                                                                    Deduction
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.DeductionsUnderChapterVIA?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TotalIncomeRounded288A ?? null) > 0 || (newRegimeData?.TotalIncomeRounded288A ?? null) > 0 || (previousData?.TotalIncomeRounded288A ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Net Income" target="_self" rel="noreferrer">
                                                                    Net Income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TotalIncomeRounded288A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? null) > 0) &&
                                                        <tr className="comp_newold_totaltextbold">
                                                            <td>Total Tax</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_purpal">{((previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtSpecialRates?.Amount ?? 0) + (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.TaxAtNormalRates?.Amount ?? 0))?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Rebate" target="_self" rel="noreferrer">
                                                                    Rebate
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell"></td>
                                                            <td className="text-right comp_newold_bgclr_lightyell"></td>
                                                            <td class="text-right comp_newold_bgclr_purpal"></td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 87A" target="_self" rel="noreferrer">
                                                                    Section 87A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateUS87A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Rebate on agricultural income" target="_self" rel="noreferrer">
                                                                    Rebate on agricultural income
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.RebateOnAgriInc?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Surcharge" target="_self" rel="noreferrer">
                                                                    Surcharge
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.Surcharge?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Health & Education Cess" target="_self" rel="noreferrer">
                                                                    Health & Education Cess
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.HEC ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Tax Payable" target="_self" rel="noreferrer">
                                                                    Tax Payable
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxPayableOtherProvisions?.GrossTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Relief" target="_self" rel="noreferrer">
                                                                    Relief
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 89" target="_self" rel="noreferrer">
                                                                    Section 89
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS89 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 90/ 90A" target="_self" rel="noreferrer">
                                                                    Section 90/ 90A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS90or90A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Section 91" target="_self" rel="noreferrer">
                                                                    Section 91
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxReliefs?.ReliefUS91 ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0 || (previousData?.TaxOnTotalIncome?.NetTaxLiability ?? null) > 0) &&
                                                        <tr>
                                                            <td> Balance Tax Payable</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.NetTaxLiability ?? 0)?.toLocaleString('hi-IN')}</td>

                                                        </tr>
                                                    }

                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? null) > 0 || (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="TDS/TCS" target="_self" rel="noreferrer">
                                                                    TDS/TCS
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{((newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{((previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnIncome ?? 0) + (previousData?.TaxOnTotalIncome?.TaxesPaid?.TdsOnSalary ?? 0) + (previousData?.TaxOnTotalIncome?.TaxesPaid?.TCS ?? 0))?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? null) > 0) &&
                                                        <tr>

                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Advance Tax" target="_self" rel="noreferrer">
                                                                    Advance Tax
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxesPaid?.AdvanceTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0 || (previousData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="Self Assessment Tax" target="_self" rel="noreferrer">
                                                                    Self Assessment Tax
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.TaxesPaid?.SelfAssessmentTax ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext'>
                                                                <span data-toggle="tooltip" data-placement="top" title="(+)  Interest" target="_self" rel="noreferrer">
                                                                    (+)  Interest
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.Amount ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? null) > 0) &&
                                                        <tr>
                                                            <td className='oldvsnewtaxreg_overflowtext pl-4'>
                                                                <span data-toggle="tooltip" data-placement="top" title="234 A" target="_self" rel="noreferrer">
                                                                    234 A
                                                                </span>
                                                            </td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234A ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4">  234 B</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234B ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4"> 234 C</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.InterestUS234C ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    {((oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0 || (newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0 || (previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? null) > 0) &&
                                                        <tr>
                                                            <td className=" pl-4"> 234 F</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(oldRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>
                                                            <td className="text-right comp_newold_bgclr_lightyell">{(newRegimeData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>

                                                            <td class="text-right comp_newold_bgclr_purpal">{(previousData?.TaxOnTotalIncome?.InterestAndFeePayable?.FeeUS234F ?? 0)?.toLocaleString('hi-IN')}</td>
                                                        </tr>
                                                    }
                                                    <tr className="comp_newold_totaltextbold">
                                                        <td>Tax Payable/Refundable</td>
                                                        <td className="text-right comp_newold_bgclr_lightyell">{oldRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (oldRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `(${(oldRegimeData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>
                                                        <td className="text-right comp_newold_bgclr_lightyell">{newRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (newRegimeData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `(${(newRegimeData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>

                                                        <td class="text-right comp_newold_bgclr_purpal">{previousData?.TaxOnTotalIncome?.AmountPayableRounded288B > 0 ? (previousData?.TaxOnTotalIncome?.AmountPayableRounded288B ?? 0)?.toLocaleString('hi-IN') : `( ${(previousData?.TaxOnTotalIncome?.RefundRounded288B ?? 0)?.toLocaleString('hi-IN')})`} </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                }


                            </div>
                        </div>
                    </div>
                }


            </>
        )
    }
    return null;
}

export default CompareRegime
