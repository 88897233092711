/* eslint-disable array-callback-return */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faIdCard, faMoneyBillAlt, faCreditCard,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import GIF_animation from '../../images/GIF_animation2.gif';
import Bank_icon from '../../images/bank-icon.png';
import { Carousel } from 'react-bootstrap';
// import { ProgressBar } from '@progress/kendo-react-progressbars';
import "./PanInfoAnimation.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { profilePANInsights } from '../../api/profileAPI';
import { FormatNumberDecimal } from '../../utils/UtilityFunctions';

class PanInfoAnimation extends React.Component {

    state = {
        loading: this.props.loading ? this.props.loading : 0,
        firstTime: false,
        params: this.props.params ? this.props.params : {},
        apiData: {},
        headerData: this.props.headerData ? this.props.headerData : {}
    };

    fetchInsightsData = async (assessee_id) => {
        this.setState({ loading: true });

        try {
            const result = await profilePANInsights(assessee_id);
            this.setState({ apiData: result?.data })
        } catch (err) {
            console.error('error: ', err);
        } finally {
            this.setState({ loading: false })
        }

    }



    componentDidMount() {
        const { params } = this.state;
        this.fetchInsightsData(params?.assessee_id)

    }

    hidePopup = () => {
        let info_icon = $(".exprit_infoiconpan");
        let self = this;
        $(".pandashpoup").animate({
            'opacity': '0.5',
            'position': 'absolute',
            'height': '10px',
            'width': '10px',
            'top': `35px`,
            'left': `${info_icon.offset().left + 1}px`,
        }, 800);
        setTimeout(function () { self.props.onHide(); }, 850);
    }



    render() {
        let { showPanInfoPopup } = this.props;

        return (
            <Modal
                show={showPanInfoPopup}
                animation={false}
                id="panslidepopup"
                className="pandashpoup"
            >
                <Modal.Body className="pt-1 pb-0">
                    <div id="bootstrap-touch-slider">
                        {this.state.apiData !== null ? <>
                            {/* true * */}
                            <Carousel
                                interval={7000}
                                controls={false}
                                pause={false}
                                fade={true}
                                className="bs-slider control-round indicators-line ciinersliderheight">


                                {/* slide 01  */}
                                <Carousel.Item className="item">
                                    <h1 className="topcont">
                                        Here is a quick look at your PAN Information
                                    </h1>
                                    <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                    <div className="bs-slider-overlay"></div>

                                    <div className="slide-text slide_style_right">
                                        <div className="animation_1">
                                            <h1 className="leftpadd">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faIdCard} /></span> Basic Details
                                            </h1>
                                            <p className="secodcontsect01">
                                                {this.state.apiData?.name !== "" ? `Hello ${this.state.apiData?.name ?? ''},` : 'Hello,'}
                                                <span className="textblodfs">
                                                    {this.state.apiData?.pan !== null && ' your PAN is'}
                                                    <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.pan ?? ''} </span>
                                                </span>
                                                <br />

                                            </p>
                                            {(this.state.apiData?.year_det?.tax_paid_amt !== null || this.state.apiData?.year_det?.total_incm_amt !== null) &&
                                                <p className="secodcontsect02">
                                                    <span className="textblodfs">
                                                        {`${this.state.apiData?.year_det?.total_incm_amt !== null && `Your Total income ${this.state.apiData?.year_det?.as_year !== null && `of AY ${this.state.apiData?.year_det?.as_year ?? ''}-${((this.state.apiData?.year_det?.as_year + 1).toString()).slice(2, 4)}`}  
                                                        ${this.state.apiData?.year_det?.total_incm_amt !== null && `is ₹ ${FormatNumberDecimal(this.state.apiData?.year_det?.total_incm_amt) ?? ''}`} `} 
                                                        ${this.state.apiData?.year_det?.tax_paid_amt !== null && this.state.apiData?.year_det?.total_incm_amt !== null ? `and you have paid ₹ ${FormatNumberDecimal(this.state.apiData?.year_det?.tax_paid_amt) ?? ''}` : `You have paid ₹ ${this.state.apiData?.year_det?.tax_paid_amt ?? ''}`}`}
                                                    </span>
                                                </p>
                                            }
                                            <p className="secodcontsect03">
                                                <span className="textblodfs ">
                                                    Here are your personal details :
                                                </span><br />
                                                <span className="textblodfs ">
                                                    <ul className='personaldetails_listbox'>
                                                        <li>
                                                            Name :
                                                            <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.name !== "" ? this.state.apiData?.name : 'Not Available'} </span>
                                                        </li>

                                                        {this.props.headerData?.assessee_status !== 1 &&
                                                            <li>
                                                                Date of Incorporation :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.incorporate_date ?? 'Not Available'} </span>
                                                            </li>
                                                        }

                                                        {this.props.headerData?.assessee_status === 1 &&
                                                            <li>
                                                                Age :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.age ?? 'Not Available'} </span>
                                                            </li>
                                                        }

                                                        {this.props.headerData?.assessee_status === 1 &&
                                                            <li>
                                                                Gender :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.gender ?? 'Not Available'} </span>
                                                            </li>
                                                        }

                                                        <li>
                                                            Residential Status :
                                                            <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.residential_status ?? 'Not Available'} </span>
                                                        </li>
                                                        <li>
                                                            Contact No :
                                                            <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.contact_info ?? 'Not Available'} </span>
                                                        </li>
                                                        <li>
                                                            Address :
                                                            <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.address ?? 'Not Available'} </span>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                </Carousel.Item>
                                {/* slide 01 */}



                                {/* slide 02  */}
                                <Carousel.Item className="item">
                                    <h1 className="topcont">
                                        Here is a quick look at your PAN Information
                                    </h1>
                                    <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                    <div className="bs-slider-overlay"></div>

                                    <div className="slide-text slide_style_right">
                                        <div className="animation_1">
                                            <h1 className="leftpadd">
                                                <span className="bussuserbox addbankiconbox"> <img src={Bank_icon} alt="Eye" width="17" /> </span> Account Details
                                            </h1>
                                            {this.state.apiData?.bank_acc_dtls?.length > 0 &&
                                                <p className="secodcontsect01">
                                                    <span className="textblodfs  PANinfo_innertile_padd">
                                                        Bank Accounts :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        <ul className='bankdetails_listbox'>
                                                            {this.state.apiData?.bank_acc_dtls?.length > 0 && this.state.apiData?.bank_acc_dtls?.map((item, index) => {
                                                                return (
                                                                    <li>
                                                                        <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}. ${item?.name ?? ''} - ${item?.acc_no ?? ''}`} </span>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </span>
                                                </p>
                                            }
                                            {this.state.apiData?.demat_acc_dtls?.length > 0 &&
                                                <p className="secodcontsect02">
                                                    <span className="textblodfs  PANinfo_innertile_padd">
                                                        Demat Accounts :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        {this.state.apiData?.demat_acc_dtls?.length > 0 ? this.state.apiData?.demat_acc_dtls?.map((item, index) => {
                                                            return (
                                                                <ul className='bankdetails_listbox'>

                                                                    <>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}.  ${item?.acc_no ?? ''}`}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${item?.mob_no ? `Mobile No. : ${item?.mob_no}` : ''}`} </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${item?.email ? `Email ID: ${item?.email}` : ''}`} </span>
                                                                        </li>
                                                                    </>
                                                                </ul>
                                                            )
                                                        })
                                                            :
                                                            <tr>No Records Found!!!</tr>
                                                        }


                                                    </span>
                                                </p>
                                            }

                                        </div>
                                    </div>
                                </Carousel.Item>
                                {/* slide 02 */}



                                {/* slide 03  */}
                                <Carousel.Item className="item">
                                    <h1 className="topcont">
                                        Here is a quick look at your PAN Information
                                    </h1>
                                    <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                    <div className="bs-slider-overlay"></div>

                                    <div className="slide-text slide_style_right">
                                        <div className="animation_1">
                                            <h1 className="leftpadd">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faIdCard} /></span> Verification and Security Details
                                            </h1>

                                            <p className="secodcontsect01">
                                                <span className="textblodfs ">
                                                    <ul className='personaldetails_listbox'>

                                                        {this.state.apiData?.is_aadhar_linked !== null &&
                                                            <li>
                                                                Aadhaar Linked with PAN :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.is_aadhar_linked} </span>
                                                            </li>
                                                        }
                                                        {this.state.apiData?.is_aadhar_otp !== null &&
                                                            <li>
                                                                Aadhaar OTP Login on or not? :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.is_aadhar_otp} </span>
                                                            </li>
                                                        }
                                                        {this.state.apiData?.is_evault_secure !== null &&
                                                            <li>
                                                                Secure with e-vault or not? :
                                                                <span className='expit_pnainfo_txetclr_blue'> {this.state.apiData?.is_evault_secure} </span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </span>
                                            </p>
                                            {this.state.apiData?.authorized_signatory?.length > 0 &&
                                                <p className="secodcontsect02">
                                                    <span className="textblodfs ">
                                                        Authorized Signatory :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        {this.state.apiData?.authorized_signatory?.length > 0 ? this.state.apiData?.authorized_signatory?.map((item, index) => {
                                                            return (
                                                                <ul className='personaldetails_listbox'>
                                                                    <>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}. ${item?.name ?? ''}`} </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`PAN : ${item?.pan ?? 'Not Available'}`} </span>
                                                                        </li>
                                                                    </>
                                                                </ul>
                                                            )
                                                        })
                                                            :
                                                            <tr>No Records Found!!!</tr>
                                                        }
                                                    </span>
                                                </p>
                                            }
                                            {this.state.apiData?.key_person_details?.length > 0 &&
                                                <p className="secodcontsect02">
                                                    <span className="textblodfs ">  
                                                        Authorized Signatory :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        {this.state.apiData?.key_person_details?.length > 0 ? this.state.apiData?.key_person_details?.map((item, index) => {
                                                            return (
                                                                <ul className='personaldetails_listbox'>
                                                                    <>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}. ${item?.name ?? ''}`} </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`PAN : ${item?.pan ?? 'Not Available'}`} </span>
                                                                        </li>
                                                                    </>
                                                                </ul>
                                                            )
                                                        })
                                                            :
                                                            <tr>No Records Found!!!</tr>
                                                        }
                                                    </span>
                                                </p>
                                            }
                                            {this.state.apiData?.representative_assessee?.length > 0 &&
                                                <p className="secodcontsect03">
                                                    <span className="textblodfs ">
                                                        Representative Assessee :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        {this.state.apiData?.representative_assessee?.length > 0 ? this.state.apiData?.representative_assessee?.map((item, index) => {
                                                            return (
                                                                <ul className='personaldetails_listbox'>
                                                                    <>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}. ${item?.name ?? ''}`} </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`PAN : ${item?.pan ?? ''}`} </span>
                                                                        </li>
                                                                    </>

                                                                </ul>
                                                            )
                                                        })
                                                            :
                                                            <tr>No Records Found!!!</tr>
                                                        }
                                                    </span>
                                                </p>
                                            }
                                            {this.state.apiData?.ca?.length > 0 &&
                                                <p className="secodcontsect04">
                                                    <span className="textblodfs ">
                                                        CA :
                                                    </span><br />
                                                    <span className="textblodfs ">
                                                        {this.state.apiData?.ca?.length > 0 ? this.state.apiData?.ca?.map((item, index) => {
                                                            return (
                                                                <ul className='personaldetails_listbox'>
                                                                    <>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`${index + 1}. ${item?.name ?? ''}`} </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className='expit_pnainfo_txetclr_blue'> {`Membership No : ${item?.memember_no ?? ''}`} </span>
                                                                        </li>
                                                                    </>

                                                                </ul>
                                                            )
                                                        })
                                                            :
                                                            <tr>No Records Found!!!</tr>
                                                        }
                                                    </span>
                                                </p>
                                            }

                                        </div>
                                    </div>

                                </Carousel.Item>
                                {/* slide 03  */}



                                {/* slide 04  */}
                                <Carousel.Item className="item">

                                    <h1 className="topcont">
                                        Here is a quick look at your PAN Information
                                    </h1>


                                    <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                    <div className="bs-slider-overlay"></div>

                                    <div className="slide-text slide_style_right Electronicsect">
                                        <div className="animation_1">
                                            <h1 className="tablefilingscreen" data-animation="animated fadeInUp ">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faMoneyBillAlt} /></span> Filed Returns
                                            </h1>
                                            <div className="tbourbor" data-animation="animated fadeInUp ">
                                                <div
                                                    className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-striped filingpantbl">
                                                        <thead className="topbornonetb">
                                                            <th>Assessment Year</th>
                                                            <th>ITR Form</th>
                                                            <th>Total Income</th>
                                                            <th>Tax Paid</th>
                                                            <th>Date of Filing</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.apiData?.itr_summary?.length > 0 ? this.state.apiData?.itr_summary.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='text-center'>{item?.assessment_year ? `${item?.assessment_year}-${((item?.assessment_year + 1).toString()).slice(2, 4)}` : '-'}</td>
                                                                        <td className='text-center'>{item?.form_type ?? '-'}</td>
                                                                        <td className='text-right'>{FormatNumberDecimal(item?.total_incm_amt) ?? '-'}</td>
                                                                        <td className='text-right'>{FormatNumberDecimal(item?.tax_paid_amt) ?? '-'}</td>
                                                                        <td className='text-center'>{item?.filling_date ?? '-'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                                :
                                                                <tr><td colSpan={5} className="text-center">No Records Found!!!</td></tr>
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="animation_2">
                                            <h1 className="tablefilingscreen titleblueclr" data-animation="animated fadeInUp ">
                                                <span className="bussuserbox"><FontAwesomeIcon icon={faCreditCard} /></span> Filed Audits
                                            </h1>
                                            <div className="tbourbor" data-animation="animated fadeInUp ">
                                                <div
                                                    className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                    <table className="table table-striped filingpantbl textblueclr">
                                                        <thead className="topbornonetb">
                                                            <th>Assessment Year</th>
                                                            <th>Return Form</th>
                                                            <th>Audit Section</th>
                                                            <th>Date of Filing</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.apiData?.audit_summary?.length > 0 ? this.state.apiData?.audit_summary?.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='text-center'>{item?.assessment_year ? `${item?.assessment_year}-${((item?.assessment_year + 1).toString()).slice(2, 4)}` : '-'}</td>
                                                                        <td className='text-center'>{item?.form_type ?? '-'}</td>
                                                                        <td className='text-right'>{item?.section_type ?? '-'}</td>
                                                                        <td className='text-center'>{item?.filling_date ?? '-'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                                :
                                                                <tr><td colSpan={5} className="text-center">No Records Found!!!</td></tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Carousel.Item>

                            </Carousel>

                        </>

                            :

                            <>

                                {/* view false */}
                                <Carousel
                                    interval={7000}
                                    controls={false}
                                    pause={false}
                                    fade={true}
                                    className="bs-slider control-round indicators-line ciinersliderheight">



                                    {/* slide 01 */}
                                    <Carousel.Item className="item">
                                        <h1 className="topcont">
                                            Here is a quick look at your PAN Information
                                        </h1>
                                        <img src={GIF_animation} alt="Bootstrap Touch Slider" className="slide-image" />
                                        <div className="bs-slider-overlay"></div>

                                        <div className="slide-text slide_style_right">
                                            <div className="animation_1">

                                                <p className="secodcontsect01" style={{ color: '#33729c' }}>
                                                    Your PAN level curated information is not updated here as the Password is Not - Verified on Client Master page.
                                                </p>
                                                <p className="secodcontsect01">

                                                    {/* Update using below button. */}
                                                </p>
                                                <p className="secodcontsect02">
                                                    <span className="textblodfs">

                                                    </span>

                                                </p>

                                            </div>
                                        </div>

                                    </Carousel.Item>
                                    {/* slide 01 */}


                                </Carousel>

                            </>
                        }


                        {/* {this.state.loading < 100 ?
                            <div className="progress_bar">
                                <ProgressBar
                                    value={this.state.loading}
                                    style={{ width: '100%' }}
                                    labelPlacement="center"
                                    label={(props) => (<strong> Loading... {this.state.loading}%</strong>
                                    )}
                                />
                            </div>
                            : */}
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            {/* <button type="button" className="GOtodashbtn btn btn-default mr-1"> Update Now</button> */}
                            <Button variant="default" className="GOtodashbtn" onClick={() => { this.hidePopup() }}>Close</Button>
                        </div>
                        {/* }  */}

                    </div>

                </Modal.Body>
            </Modal >
        );
    }
}
export default PanInfoAnimation;
