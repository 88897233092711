import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ShowAlert(props) {
  const { warning, success, error, onClose, autoHideTime = 3000, autoHideError = true, position = "bottom-right", theme = "colored" } = props;
  let showToast = useRef();
   const [show, setShow] = useState(null);

  useEffect(() => {
    if (warning || error || success) {

      if (success) {
        toast.success(success, {
          position: position,
          autoClose: autoHideError ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastSuccess",
          onClose: () => onClose(),
        });
      } else if (error) {
        toast.error(error, {
          position: position,
          autoClose: autoHideError ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastError",
          onClose: () => onClose(),
        });
      } else if (warning) {
        toast.warn(warning, {
          position: position,
          autoClose: autoHideError ? autoHideTime : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme,
          toastId: "toastWarning",
          onClose: () => onClose(),
        });
      }
      
      showToast.current =( <ToastContainer
        position={position}
        autoClose={autoHideError ? autoHideTime : false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      />)
      setShow(showToast);
      
    }
    //eslint-disable-next-line
  }, [success, error, warning]);



  return (<>
    {show?.current}
    </>)
}