/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';

const initialData = {
  flag_cy: '',
  flag_py: '',
  netTaxCyNew: 0,
  netTaxCyOld: 0,
};
export const regimeSlice = createSlice({
  name: 'regime',
  initialState: initialData,
  reducers: {
    setFieldValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setRegime: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = action.payload[key];

      });
    },
  },
});

export const { setFieldValue, setRegime } = regimeSlice.actions;
export const selectRegimeData = state => state.regime;
export default regimeSlice.reducer;
